import '../styles/global.scss';
import '../styles/pages/privacy.scss';

import {HeadFC, Link} from "gatsby";

import {
    BigLogoImage
} from "../images";
import React, {useState} from 'react'
import MainFooter from '../components/MainFooter';

const Privacy = () => {

    return (
        <>
            <div className="DocPage">
                <div className="DocPageLogo">
                    <Link to="/">
                        <img src={BigLogoImage} width={200} alt=""/>
                    </Link>
                </div>
                <div className="DocPageWrapper">
                    <h2>COOKIE POLICY</h2>

                    <p>Edition effective since: June 23, 2022</p>

                    <h3>1. INTRODUCTION</h3>

                    <p>“Cookie” is a small text file which is placed onto your device (e.g. computer, smartphone or another electronic device) when you use our Website. In a very limited scope cookies may also
                    exist within mobile app. Under the same rules and principles, we may also use web beacons (a web beacon is a technique used on web pages and email to unobtrusively (usually invisibly)
                    allow checking that a user has accessed some content). <br /><br />
                    Website - website available at URL: https://aeer-platform.com/. <br /><br />
                    Mobile app – AEER mobile application that can be downloaded via Appstore or Google Play. <br /><br />
                    “We” (as defined below) adopted this Privacy Policy and act as the data controller of our Website, offering you the “Software” (as defined below). A “controller” is a person or organization
                    who alone or jointly determines the purposes for which, and the manner in which, any personal data is, or is likely to be, processed. <br /><br />
                    We are: <br /><br />
                    AEER Platform Inc, a company organized under the laws of the State of Delaware, United States, with the following registered address: 8 The Green Ste A Dover, DE 19901, United States
                    of America (also: “AEER”, “AEER Platform”, “we”, “We”, “our”, “us”, as applicable). <br /><br />
                    We use the cookies that do not store personally identifiable information, nor can they harm your computer/device. As is common practice with almost all professional websites this Website
                    uses cookies, cookies are text files containing small amounts of information which are downloaded to your device (e.g. computer, smartphone, electronic device which accesses the internet)
                    when you visit a website. Cookies can be useful because they allow a website to recognize a user’s device, store some information about your preferences or past actions and generally help
                    to improve your online experience. We may use cookies and other similar tracking technologies such as [GA, Facebook pixel, LinkedIn pixel] on our Website. For example, we may monitor
                    how many times you visit the website, which pages you go to, traffic data, location data and the originating domain name of a user`s internet service provider. This information helps us to
                    build a profile of our users. Some of this data will be aggregated or statistical, which means that we will not be able to identify you individually. This cookie data can then be retrieved and
                    can allow us to customize our web pages and services accordingly. You can find more information about cookies at: www.aboutcookies.org or www.allaboutcookies.org and
                    www.youronlinechoices.eu <br /><br />
                    “You” - references in this Policy to “you” or “your” are references to individuals who use the Website. The Website is not intended for children, and we do not knowingly collect personal
                    data relating to children. <br /><br />
                    “Policy” is this cookie policy that applies to the use of cookies and other similar technologies on our Website. Please read this Policy carefully as it contains important information on who we
                    are and our use of cookies or similar technologies on our Website. The purpose of this Policy is to provide you with information about what cookies are and how they work. It also explains
                    what cookies we use, what they do and how you can control what cookies we use when you visit our Website. This Policy should be read alongside our Privacy Policy which gives full details
                    of how and why we use your personal </p>

                    <h3>2. WHO IS THE CONTROLLER? LODGING COMPLAINT OR REQUEST</h3>
                    <p>Unless we notify you otherwise, we are the controller for your personal data. This Website includes links to third-party websites, plug-ins and applications. Clicking on those links or enabling
                    those connections may allow third parties to collect or share data about you. Where you have opted in to Functional Cookies, Analytics Cookies, and/ or Advertising Cookies, it may be possible
                    for third-party websites to track your browsing behaviour when you leave the Website. You can opt out of these cookies at any time using cookie settings. We do not control third-party
                    websites and are not responsible for their privacy statements. When you leave our Website, we encourage you to read the privacy Policy of every website you visit.
                    If you have any questions about this Cookies Policy, need to lodge a complaint or request, please contact us.</p>	

                    <h3>3. WHAT TYPES OF COOKIES DO WE USE AND HOW LONG DO THEY LAST?</h3>
                    <p>There are four types of cookies that we use, they perform different functions: (1) necessary cookies, (2) functional cookies, (3) analytics cookies and (4) advertising cookies. </p>

                    <table className="table1">
                        <tbody>
                            <tr>
                                <th></th>
                                <th>COOKIES’ FUNCTIONS AND/OR PURPOSE FOR USING COOKES </th>
                                <th>LEGAL BASIS FOR PROCESSING</th>
                            </tr>
                            <tr>
                                <td>
                                    <b>NECESSARY COOKIES</b> <br />
                                    Also known as: <br />
                                    - “strictly necessary cookies” or <br />
                                    - “essential cookies”
                                </td>
                                <td>
                                    Necessary cookies are essential in order to provide you with the services you request on our Website and in
                                    order that we can comply with legal obligations such as our security obligations under data protection law.
                                    We do not need to ask for your consent in order to use these cookies as without them we would not be able
                                    to provide the services requested by you. These are always active on the Website.
                                </td>
                                <td>
                                    Necessary for our legitimate interests:
                                    It is in our interests to be able to
                                    provide our Website users with services
                                    they request and to enable the
                                    operation of our Website.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>FUNCTIONAL COOKIES</b> <br />
                                    Also known as: <br />
                                    - “functionality cookies” <br />
                                    - “performance cookies”
                                </td>
                                <td>
                                    Functional cookies allow us to provide a better user experience on this Website, such as by remembering
                                    your log-in details or optimizing video performance. These cookies allow our Website to remember the
                                    choices you make (such as your user name, language, last action and search preferences) and provide
                                    enhanced, more personal features. The information collected by these cookies is anonymous and cannot
                                    track your browsing activity on other websites.
                                </td>
                                <td>Consent</td>
                            </tr>
                            <tr>
                                <td>
                                    <b>ANALYTICS COOKIES</b> <br />
                                    Also known as: <br />
                                    - “statistics cookies”
                                </td>
                                <td>
                                    Analytics cookies collect information about things like how many people visit our Website, what parts of the
                                    Website people look at and how many new visitors we have to the Website. These cookies help us to monitor
                                    how effective our Website is and to improve it, tailoring it to our visitors.
                                </td>
                                <td>Consent</td>
                            </tr>
                            <tr>
                                <td>
                                    <b>ADVERTISING COOKIES</b> <br />
                                    Also known as: <br />
                                    - “marketing cookies” <br />
                                    - “targeting cookies”<br />
                                </td>
                                <td>
                                    Advertising Cookies are used to provide you with access to content and information that you may be
                                    interested in, for example, we have links to our presence on social media sites where sharing of our content
                                    on those sites is facilitated by cookies tools.
                                </td>
                                <td>Consent</td>
                            </tr>		
                        </tbody>
                    </table>

                    <p>Cookies may also be classified under two other criteria.</p>
                    <table className="table2">
                        <tbody>
                            <tr>
                                <th>Depending on who downloads cookies:</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>(i) first party cookies and </td>
                                <td>First party cookies are set by this Website you are visiting.
                                If you subscribe with us, then we will use cookies for the management of the signup process and general administration. These cookies will usually be
                                deleted when you log out however in some cases they may remain afterwards to remember your site preferences when logged out.
                                We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log in every single time you visit a new
                                page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged
                                in. This site offers newsletter or email subscription services and cookies may be used to remember if you are already registered and whether to show
                                certain notifications which might only be valid to subscribed/unsubscribed users.</td>
                            </tr>
                            <tr>
                                <td>(ii) third-party cookies</td>
                                <td>Third party cookies are set by a domain which is different to the website you are visiting, an example of this is where cookies are placed on your device
                                by third parties when you share content from our Website on social media websites.
                                This site uses Google Analytics, which are the most widespread and trusted analytics solution on the web for helping us to understand how you use
                                the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that
                                you visit so we can continue to produce engaging content. For more information on Google Analytics cookies, see the official Google Analytics pages.
                                As we sell services it is important for us to understand statistics about how many of the visitors to our site fill out website forms intended for that. As
                                such this is the kind of data that these cookies will track. This is important to you as it means that we can accurately make business predictions that
                                allow us to monitor our advertising and product costs to ensure the best possible price. <br /> To enrich our website content, sometimes we may embed video content from other social media websites such as YouTube, Vimeo or Facebook. As a
                                result, when you visit a page with content embedded, you may be presented with cookies from these websites. Acumen International has no control
                                or liability over these cookies set, so you should check the relevant third party`s cookie policy for more information.
                                We also offer a “share page” widget on some of our web pages, where content can be shared easily on the following sites Facebook, Twitter, LinkedIn
                                and Google+. These sites may set a cookie when you are logged into their service. Acumen International has no control or liability over these cookies
                                set, so you should check the relevant third party`s cookie policy for more information</td>
                            </tr>
                            <tr>
                                <th>Depending on how long and where cookies stay:</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>
                                    (a) session cookies or
                                </td>
                                <td>Session cookies are cookies which are deleted when you close your internet browser</td>
                            </tr>
                            <tr>
                                <td>(b) persistent cookies</td>
                                <td>Persistent cookies stay in place after you have closed your browser, for example, a cookie which tracks whether you are a new or return visitor to the Website. Duration of stay may vary.</td>
                            </tr>
                        </tbody>
                    </table>		

                    <h3>4. CONSENT</h3>
                    <p>Necessary Cookies will always be active on your device, you cannot give consent or reject consent for these cookies. We ask for your consent to place Functional, Analytics and Advertising
                    Cookies and other similar technologies on your device. If at any time you want to change your cookie settings, including withdrawing your consent to this processing, we would encourage
                    you to do this via the cookie settings. You may need to refresh the page before the changes to the settings take effect. <br /><br />
                    Unfortunately, in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended
                    that you leave on all cookies if you are not sure whether you need them or not in case, they are used to provide a service that you use</p>

                    <h3>5. CHANGES TO THE NOTICE</h3>
                    <p>This version of Policy is effective from the date indicated on the top of first page. Any prior versions of this notice can be obtained by contacting us at contact details on the website. We
                    reserve right to change this Policy at any time, including immediate effect. </p>

                    <h3>6. HOW TO BLOCK ALL COOKIES AND THE CONSEQUENCES OF THIS</h3>
                    <p>If you want to block all cookies, you may be able to do this through the settings in your browser for each browser you use and each device you use to access the internet. Please be aware
                    that some areas of our Website may not work if your browser does not accept cookies. The “Help” function within your web browser should tell you how to make these changes,
                    alternatively www.allaboutcookies.org provides further information on how to do this and on how to delete cookies.</p>

                    <h3>7. COOKIE CHART</h3>

                    <table className="table3">
                        <tbody>
                            <tr>
                                <th>Name of cookie</th>
                                <th>Domain which sets the cookie</th>
                                <th>What does the cookie do?</th>
                                <th>First or third party cookie?</th>
                                <th>Cookie retention period</th>
                            </tr>
                            <tr>
                                <td>Content will be updated later</td>
                                <td>Content will be updated later</td>
                                <td>Content will be updated later</td>
                                <td>Content will be updated later</td>
                                <td>Content will be updated later</td>
                            </tr>
                            <tr>
                                <td>utm_gif</td>
                                <td>.google.com</td>
                                <td>Google Analytics Tracking Code that logs details about the visitor's browser and computer.</td>
                                <td>3rd party</td>
                                <td>Session cookie</td>
                            </tr>
                            <tr>
                                <td>_ga</td>
                                <td>.google.com</td>
                                <td>This cookie is used for Google Analytics to distinguish unique users by assigning a randomly generated number as a client identifier.</td>
                                <td>3rd party</td>
                                <td>2 years</td>
                            </tr>
                            <tr>
                                <td>_gid</td>
                                <td>.google.com</td>
                                <td>This cookie is used for Google Analytics to store and update a unique value for each page visited.</td>
                                <td>3rd party</td>
                                <td>1 day</td>
                            </tr>

                            <tr>
                                <td>_gat_gtag_UA_16462053_ 13</td>
                                <td>.google.com </td>
                                <td>This cookie is used to identify unique users by Google Analytics</td>
                                <td>3rd party</td>
                                <td>1 minute</td>
                            </tr>
                            <tr>
                                <td>CONSENT</td>
                                <td>.google.com </td>
                                <td>A Google Analytics cookie that helps us gather
                                statistics on how customers use our website, so that
                                we can improve our service.</td>
                                <td>3rd party</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>JSESSIONID</td>
                                <td>Infusionsoft</td>
                                <td>This cookie allows us to preserve users states across page requests</td>
                                <td>3rd party</td>
                                <td>When you close your browser</td>
                            </tr>
                            <tr>
                                <td>Infusionsoft Tracking Cookie</td>
                                <td>Infusionsoft</td>
                                <td>This cookie feeds information to our CRM system
                                InfusionSoft to ensure the content we send you is
                                relevant and appropriate.</td>
                                <td>3rd party</td>
                                <td>1 year</td>
                            </tr>
                            <tr>
                                <td>OIDI</td>
                                <td>.bing.com</td>
                                <td>This is set by a third party service Bing for advertisement tracking purposes</td>
                                <td></td>
                                <td>30 days</td>
                            </tr>
                            <tr>
                                <td>MUID</td>
                                <td>.bing.com</td>
                                <td>Microsoft User Identifier tracking cookie used by Bing Ads</td>
                                <td>3rd party</td>
                                <td>1 year</td>
                            </tr>
                            <tr>
                                <td>WLS</td>
                                <td>.bing.com</td>
                                <td>This cookie is used by Bing</td>
                                <td>3rd party</td>
                                <td>Session</td>
                            </tr>

                            <tr>
                                <td>.bing.com </td>
                                <td>This cookie is an analytics service that connects data
                                from the Bing advertising network with actions
                                performed on the website.</td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>SRCHUSR </td>
                                <td>.bing.com </td>
                                <td>This cookie is an analytics service that connects data
                                from the Bing advertising network with actions
                                performed on the website.</td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>SRCHD </td>
                                <td>.bing.com </td>
                                <td>This cookie is an analytics service that connects data
                                from the Bing advertising network with actions
                                performed on the website.</td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>SRCHUID </td>
                                <td>.bing.com </td>
                                <td>This cookie is an analytics service that connects data
                                from the Bing advertising network with actions
                                performed on the website.</td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>__Secure-3PSID </td>
                                <td>.google.com </td>
                                <td>Builds a profile of website visitor interests to show
                                relevant and personalized ads through retargeting.</td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>__Secure-3PSIDCC </td>
                                <td>.google.com </td>
                                <td>Builds a profile of website visitor interests to show
                                relevant and personalized ads through retargeting.</td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>__Secure-3PAPISID </td>
                                <td>.google.com </td>
                                <td>Builds a profile of website visitor interests to show
                                relevant and personalized ads through retargeting.</td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>CONSENT </td>
                                <td>.google.com </td>
                                <td>Stores visitors’ preferences and personalizes ads. </td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>__Secure-3PAPISID </td>
                                <td>.google.com </td>
                                <td>Used by Google to store user preferences and
                                information on Google maps.</td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>SAPISID </td>
                                <td>.google.com </td>
                                <td>Used by Google to store user preferences and
                                information on Google maps.</td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>__Secure-1PAPISID </td>
                                <td>.google.com </td>
                                <td>Used by Google to store user preferences and
                                information on Google maps.</td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>APISID</td>
                                <td>.google.com </td>
                                <td>Used by Google to store user preferences and
                                information on Google maps.</td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>SID </td>
                                <td>.google.com </td>
                                <td>Security cookie to confirm visitor authenticity,
                                prevent fraudulent use of login data and protect
                                visitor data from unauthorized access.</td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>SSID </td>
                                <td>.google.com</td>
                                <td>Google collects visitor information for videos hosted
                                by YouTube on maps integrated with Google Maps.</td>
                                <td>3rd party </td>
                                <td>Persistent</td>
                            </tr>

                            <tr>
                                <td>HSID </td>
                                <td>.google.com </td>
                                <td>Security cookie to confirm visitor authenticity,
                                prevent fraudulent use of login data and protect user
                                data from unauthorized access.</td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>SAPISID</td>
                                <td>.google.com</td>
                                <td>Google collects visitor information for videos hosted
                                by YouTube.</td>
                                <td>3rd party </td>
                                <td>Persistent</td>
                            </tr>
                            
                            <tr>
                                <td>__cf_bm </td>
                                <td>.infusionsoft.app </td>
                                <td></td>
                                <td>3rd party</td>
                                <td>30 minutes</td>
                            </tr>

                            <tr>
                                <td>linkedJumpLog </td>
                                <td>Infusionsoft </td>
                                <td>Used by LinkedIn for tdacking of embedded services </td>
                                <td>3rd party</td>
                                <td>15 years</td>
                            </tr>

                            <tr>
                                <td>liveagent_oref</td>
                                <td>Infusionsoft </td>
                                <td>This cookie feeds information to our CRM system
                                InfusionSoft to ensure the content we send you is
                                relevant and appropriate.</td>
                                <td>3rd party </td>
                                <td>10 years</td>
                            </tr>

                            <tr>
                                <td>liveagent_ptid </td>
                                <td>Infusionsoft </td>
                                <td>This cookie name is associated with technology from
                                LiveAgent, which provides online chat and customer
                                support functionality.</td>
                                <td>3rd party </td>
                                <td>10 years</td>
                            </tr>

                            <tr>
                                <td>liveagent_sid </td>
                                <td>Infusionsoft</td>
                                <td>This cookie name is associated with technology from
                                LiveAgent, which provides online chat and customer
                                support functionality.</td>
                                <td>3rd party </td>
                                <td>Session cookie</td>
                            </tr>

                            <tr>
                                <td>liveagent_vc</td>
                                <td>Infusionsoft</td>
                                <td>This cookie name is associated with technology from
                                LiveAgent, which provides online chat and customer
                                support functionality.</td>
                                <td>3rd party </td>
                                <td>Session cookie</td>
                            </tr>

                            <tr>
                                <td>SID </td>
                                <td>.linkedin.com </td>
                                <td>Used to determine what a visitor is doing before they
                                convert on a LinkedIn microsite</td>
                                <td>3rd party</td>
                                <td>Session cookie</td>
                            </tr>

                            <tr>
                                <td>s_tslv</td>
                                <td>.linkedin.com </td>
                                <td>Used to retain and fetch time since last visit in Adobe
                                Analytics</td>
                                <td>3rd party </td>
                                <td>6 months</td>
                            </tr>

                            <tr>
                                <td>s_ppv</td>
                                <td>.linkedin.com</td>
                                <td> Used by Adobe Analytics to retain and fetch what
                                percentage of a page was viewed</td>
                                <td>3rd party </td>
                                <td>Session cookie</td>
                            </tr>

                            <tr>
                                <td>s_tp</td>
                                <td>.linkedin.com </td>
                                <td>Tracks percent of page viewed </td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>s_pltp </td>
                                <td>.linkedin.com</td>
                                <td>Provides page name value (URL) for use by Adobe
                                Analytics</td>
                                <td>3rd party</td>
                                <td>Session cookie</td>
                            </tr>

                            <tr>
                                <td>AMCV_14215E3D5995C5 7C0A495C55%40AdobeOr g</td>
                                <td>.linkedin.com </td>
                                <td>Indicates the start of a session for Adobe Experience
                                Cloud</td>
                                <td>3rd party </td>
                                <td>Session cookie</td>
                            </tr>

                            <tr>
                                <td>AMCV_14215E3D5995C5
                                7C0A495C55%40AdobeOrg</td>
                                <td></td>
                                <td>Unique Identifier for Adobe Experience Cloud </td>
                                <td>3rd party</td>
                                <td>180 days</td>
                            </tr>

                            <tr>
                                <td>lidc </td>
                                <td>.linkedin.com </td>
                                <td>To facilitate data center selection</td>
                                <td>3rd party </td>
                                <td>24 hours</td>
                            </tr>

                            <tr>
                                <td>_gid </td>
                                <td>.linkedin.com </td>
                                <td>Google Analytics cookie </td>
                                <td>3rd party </td>
                                <td>1 day</td>
                            </tr>

                            <tr>
                                <td>at_check </td>
                                <td>.linkedin.com </td>
                                <td>Used to determine if a visitor has accepted the use of
                                cookies for Adobe Target</td>
                                <td>3rd party </td>
                                <td>Session cookie</td>
                            </tr>

                            <tr>
                                <td>sdsc </td>
                                <td>.linkedin.com</td>
                                <td>Signed data service context cookie used for database
                                routing to ensure consistency across all databases
                                when a change is made. Used to ensure that userinputted content is immediately available to the
                                submitting user upon submission.</td>
                                <td>3rd party </td>
                                <td>Session cookie</td>
                            </tr>

                            <tr>
                                <td>lms_ads</td>
                                <td>.linkedin.com </td>
                                <td>Used to identify LinkedIn Members off LinkedIn in the
                                Designated Countries for advertising</td>
                                <td>3rd party </td>
                                <td>30 days</td>
                            </tr>

                            <tr>
                                <td>li_gc </td>
                                <td></td>
                                <td></td>
                                <td>3rd party</td>
                                <td></td>
                            </tr>

                            <tr>
                                <td>UserMatchHistory </td>
                                <td>.linkedin.com </td>
                                <td>LinkedIn Ads ID syncing </td>
                                <td>3rd party </td>
                                <td>30 days</td>
                            </tr>

                            <tr>
                                <td>mbox </td>
                                <td>.linkedin.com </td>
                                <td>Used by Adobe Target to analyze the relevance of
                                online content</td>
                                <td>3rd party</td>
                                <td>180 days</td>
                            </tr>

                            <tr>
                                <td>_guid </td>
                                <td>.linkedin.com </td>
                                <td>Used to identify a LinkedIn Member for advertising
                                through Google Ads</td>
                                <td>3rd party </td>
                                <td>90 days</td>
                            </tr>

                            <tr>
                                <td>_gcl_au </td>
                                <td>.linkedin.com</td>
                                <td>Used through Google Analytics to understand user
                                interaction with the site and advertising</td>
                                <td>3rd party </td>
                                <td>3 months</td>
                            </tr>

                            <tr>
                                <td>bcookie </td>
                                <td>.linkedin.com </td>
                                <td>Browser Identifier cookie to uniquely identify devices
                                accessing LinkedIn to detect abuse on the platform</td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>lang </td>
                                <td>.linkedin.com </td>
                                <td>Used to remember a user's language setting to ensure
                                LinkedIn.com displays in the language selected by the
                                user in their settings.</td>
                                <td>3rd party </td>
                                <td>Session cookie</td>
                            </tr>

                            <tr>
                                <td>spectroscopyId</td>
                                <td>.linkedin.com</td>
                                <td>Used to catch malicious activity through browser
                                extensions</td>
                                <td>3rd party </td>
                                <td>Session cookie</td>
                            </tr>

                            <tr>
                                <td>s_ips</td>
                                <td>.linkedin.com </td>
                                <td>Tracks percent of page viewed </td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>_ga</td>
                                <td>.linkedin.com </td>
                                <td>Google Analytics cookie </td>
                                <td>3rd party </td>
                                <td>2 years</td>
                            </tr>

                            <tr>
                                <td>li_sugr </td>
                                <td>.linkedin.com </td>
                                <td>Used to make a probabilistic match of a user's identity
                                outside the Designated Countries</td>
                                <td>3rd party </td>
                                <td>90 days</td>
                            </tr>

                            <tr>
                                <td>lms_analytics </td>
                                <td>.linkedin.com </td>
                                <td>Used to identify LinkedIn Members in the Designated
                                Countries for analytics</td>
                                <td>3rd party </td>
                                <td>30 days</td>
                            </tr>

                            <tr>
                                <td>liap</td>
                                <td>.linkedin.com </td>
                                <td>Used by non
                                -www.domains to denote the logged in
                                status of a member</td>
                                <td>3rd party</td>
                                <td>1 year</td>
                            </tr>

                            <tr>
                                <td>aam_uuid </td>
                                <td>.linkedin.com</td>
                                <td>Set for ID sync for Adobe Audience Manager 3rd party 30 days
                                AnalyticsSyncHistory .linkedin.com Used to store information about the time a sync took
                                place with the lms_analytics cookie</td>
                                <td>3rd party </td>
                                <td>30 days</td>
                            </tr>

                            <tr>
                                <td>s_sq </td>
                                <td>.linkedin.com </td>
                                <td>Used to store information about the previous link that
                                was clicked on by the user by Adobe Analytics</td>
                                <td>3rd party </td>
                                <td>Session cookie</td>
                            </tr>

                            <tr>
                                <td>s_plt </td>
                                <td>.linkedin.com</td>
                                <td>Tracks the time that the previous page took to load</td>
                                <td>3rd party </td>
                                <td>Session cookie</td>
                            </tr>

                            <tr>
                                <td>gpv_pn </td>
                                <td>.linkedin.com </td>
                                <td>Used to retain and fetch previous page visited in
                                Adobe Analytics</td>
                                <td>3rd party </td>
                                <td>6 months</td>
                            </tr>

                            <tr>
                                <td>s_cc </td>
                                <td>.linkedin.com </td>
                                <td>Used to determine if cookies are enabled for Adobe
                                Analytics</td>
                                <td>3rd party </td>
                                <td>Session cookie</td>
                            </tr>

                            <tr>
                                <td>_fbp </td>
                                <td>Facebook </td>
                                <td>Used by Facebook to deliver a series of advertisement
                                products such as real time bidding from third party
                                advertisers</td>
                                <td>First-party </td>
                                <td>3 months</td>
                            </tr>

                        </tbody>
                    </table>
                </div>	
            </div>
            <MainFooter />
        </>
    )
}

export default Privacy;

export const Head: HeadFC = () => (
    <>
        <title>Cookie policy - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
